import React from "react"
import Layout from "../../components/organisms/layout/layout";

export default function Home() {
    return (
        <Layout>
            Apps
        </Layout>
    )
}
